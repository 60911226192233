import React from 'react';
import { css } from 'glamor';
import * as styles from './articlepage.style';
import axios from 'axios';
import HeaderSection from '../../components/common/header/header';
import HeaderTabletSection from '../../components/common/header/header.tablet';
import FooterSection from '../../components/common/footer/footer';
import NavBar from '../../components/navbar/navbar';
import * as navigation from '../../configs/navigation';
import * as languages from '../../configs/languages';
import Search from '../search/search';
import ArticlePageView from './ArticlePageView';
import ExclusiveContentWarning from './ExclusiveContentWarning';
import * as translate from '../../assets/translations/translations';

const IsProfessionalKey = 'disclaimer_isProfessional';

export default class ArticlePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            article: {},
            articleLoaded: false,
            showSearch: false,
            articleNotFound: false
        };
        this.showSearchComponent = this.showSearchComponent.bind(this);
    }

    showSearchComponent = (value) => {
        this.setState({ showSearch: value });
        setTimeout(function () { if (document.getElementById("searchText") != null) { document.getElementById("searchText").focus(); } }, 300);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = 'Preview - ' + translate.strings.TR14
        let language = this.props.match.params.langauge;
        let selectedLanguageLocale = languages.Languages.filter((edition) => edition.edition == language)

        this.setState({ articleId: this.props.match.params.articleid })
        axios({
            url: window.env.ArticleUrl + this.props.match.params.articleid,
            method: 'get',
            data: null,
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': selectedLanguageLocale[0].langaugeLocale
            }
        }).then(res => {
            if (res.data == 'article not found') {
                this.setState({ articleNotFound: true });
            }
            else {
                this.setState({ article: res.data });
                this.setState({ articleLoaded: true });
            }
        }).catch(err => {
            this.setState({ articleNotFound: true });
        });;
    }

    render() {
        var articleShown = !this.state.article.exclusive || (localStorage.getItem(IsProfessionalKey) == 'true');
        return (
            <div>
                <div>
                    <HeaderTabletSection history={this.props.history} />
                    <HeaderSection showSearch={this.showSearchComponent} showSearchButton={!this.props.match.params.commr} navEvent={this.navigationOpenEvent} history={this.props.history} />
                    {this.props.match.params.commr ? <NavBar items={navigation.commrNavInfo} commr={true} navEvent={this.navigationOpenEvent} /> : <NavBar items={navigation.navInfo} navEvent={this.navigationOpenEvent} />}
                    {this.state.showSearch ? <Search homepage={false} /> : null}
                </div>

                {this.state.articleLoaded ?
                    articleShown ?
                        <ArticlePageView article={this.state.article} commr={this.props.match.params.commr ? true : false} />
                        : <ExclusiveContentWarning />
                    : this.state.articleNotFound ? <div className="container-fluid" {...css(styles.articleContent)}> <section>
                        <div> <p {...css(styles.paragraph)}>{translate.strings.TR239}</p> </div>
                    </section></div> : <div className="container-fluid" {...css(styles.articleContent)} />
                }
                <FooterSection commr={this.props.match.params.commr} />
            </div>
        )
    }
}