import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './signup.style';
import SignupImage from '../../assets/images/tablet_and_phone.png';
import { Link } from 'react-router-dom';
import * as translate from '../../assets/translations/translations';

export default class SignUpSection extends Component {
  render() {
    return (
      <section {...css(styles.signupContainer)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-sm-6 col-12" {...css(styles.textSection)} role="region" aria-label={translate.strings.TR29}>
              <h2 {...css(styles.signupH2)}>{translate.strings.TR29}</h2>
              <p  {...css(styles.signupParagraph)} style={{ lineHeight: '1.5' }}>{translate.strings.TR30}</p>

              <Link aria-label={translate.strings.TR31} alt={translate.strings.TR31} {...css(styles.signupButton)} to={`/subscription`}>{translate.strings.TR31}</Link>

            </div>
            <div className="col-md-6 col-lg-6 col-sm-6 col-12">
              <img src={SignupImage} {...css(styles.researchImage)} alt={"Research Newsletter Signup"} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}