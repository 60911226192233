import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './card.style';
import CardThumbnail from '../../assets/images/Danske_bank_default.jpg';
import ShareIcon from '../../assets/icons/share_darkblue.svg';
import rightArrow from '../../assets/icons/arrow-link-next_darkblue.svg';
import chevronRight from '../../assets/icons/chevronRight.svg';
import { Link } from 'react-router-dom';
import * as translate from '../../assets/translations/translations';
import catalogTranslations from '../../assets/translations/subcategories.json';

export default class Card extends Component {

    constructor(props) {
        super(props);

        this.redirecttoArticle = this.redirecttoArticle.bind(this);
    }

    handleFile(filepath) {
        window.open(filepath, '_blank');
    }

    formatDate(date) {
        var date = new Date(date);
        return date.toLocaleDateString("da-DK").replace(/-/g, "/");
    }

    formatKeyword(keywords) {
        if (keywords == undefined || keywords == null) return;
        return <Link to={`/keywords/${keywords}`} onClick={(e) => e.stopPropagation()} aria-label={keywords ? keywords : 'keywords'} alt={keywords ? keywords : 'keywords'}> {keywords ? <span {...css(styles.focusTag)}>{keywords}</span> : null} </Link>
    }

    renderThumbnail(imagepath, altText) {
        if (imagepath == undefined || imagepath == null) {
            return <img className="card-image" src={CardThumbnail} alt={altText ? altText : 'Default Thumbnail'} {...css(styles.cardImage)} />
        }
        else {
            return <img className="card-image" src={imagepath} alt={altText ? altText : "Article Thumbnail"} {...css(styles.cardImage)} />
        }
    }

    getCatalogtranslations(text) {
        let edition = localStorage.getItem('language') || 'EN';
        if (edition == 'EN' || edition == 'ALL') {
            return text;
        }
        else {
            if (catalogTranslations[text]) {
                let translationElement = catalogTranslations[text];
                let translatedText = translationElement[edition];
                return translatedText;
            }
            else {
                return text;
            }
        }
    }

    redirecttoArticle(event, articleid, language) {
        event.stopPropagation();
        this.props.history.push(`/article/${articleid}/${language}`)
    }

    renderSubcategories(categoryInfo) {
        const ciCount = categoryInfo.length;
        return categoryInfo.map((ci, i) => (
            <span key={i}>
                {!!i && " "}
                <Link aria-label={this.getCatalogtranslations(ci.subcategory)} alt={this.getCatalogtranslations(ci.subcategory)} to={`/articlelist/${ci.subcategory.replace(/\s+/g, '-')}`}  {...css(styles.cardAnchor)} onClick={(e) => e.stopPropagation()} >{this.getCatalogtranslations(ci.subcategory)}{ciCount !== i + 1 && ","}</Link>
            </span>
        ));
    }

    render() {
        return (
            <div className="card" {...css(styles.card)} onClick={(e) => this.redirecttoArticle(e, this.props.articleid, this.props.langauge)} role='region' aria-label={'Articles'}>
                {this.renderThumbnail(this.props.thumbnail, this.props.title)}
                <div className="card-body"  {...css(styles.cardBody)}>
                    {this.formatKeyword(this.props.keywords)}
                    {this.renderSubcategories(this.props.categoryInfo)}
                    <p {...css(styles.cardDate)} >{this.formatDate(this.props.publisheddate)}</p>
                    <p {...css(styles.cardTitle)}>{this.props.title}</p>
                    <p dangerouslySetInnerHTML={{ __html: this.props.summary }} {...css(styles.cardText)} className="card-summaryText"></p>
                    <div {...css(styles.shareContainer)}>
                        {/*<span>
                            <img src={ShareIcon} />
                        </span>*/}
                        <Link {...css(styles.readMore)}
                            aria-label={translate.strings.TR18}
                            alt={translate.strings.TR18}
                            to={{
                                pathname: `/article/${this.props.articleid}/${this.props.langauge}`
                            }}>
                            {translate.strings.TR18} <img {...css(styles.rightArrow)} src={chevronRight} alt={"Right Arrow"} />{/*<img {...css(styles.rightArrow)} src={rightArrow} alt={"Right Arrow"} />*/}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}