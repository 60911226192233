import React from 'react';
import HeaderSection from '../common/header/header';
import Search from '../search/search';
import InFocusSection from '../../containers/infocus/infocus'
import LatestResearch from '../../containers/latestresearch/latestresearch';
import PopularSection from '../../containers/popularsection/popularsection';
import NewSection from '../../containers/newsection/newsection';
import SignUpSection from '../../components/signup/signup';
import FooterSection from '../common/footer/footer';
import NavBar from '../../components/navbar/navbar';
import PodcastChannelSelection from '../../components/podcastchannelselection/podcastchannelselection';
import * as navigation from '../../configs/navigation';
import ExternalContent from '../../containers/externalcontent/externalcontent';
import HeaderTabletSection from '../common/header/header.tablet';
import * as translate from '../../assets/translations/translations';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.showSearchComponent = this.showSearchComponent.bind(this);
    }

    showSearchComponent = (value) => {
        document.getElementById("searchText").focus();
    }

    togglePodcastsVisibility = () => {
        this.podcasts.togglePodcastsVisibility();
    }

    componentDidMount() {
        document.title = translate.strings.TR14
        if (this.props.location.state && this.props.location.state.showPodcasts) {
            this.podcasts.open();
        }
    }

    render() {
        return (
            <div>
                <HeaderTabletSection history={this.props.history} togglePodcastsVisibility={this.togglePodcastsVisibility} />
                <HeaderSection showSearch={this.showSearchComponent} history={this.props.history} />
                <NavBar items={navigation.navInfo} togglePodcastsVisibility={this.togglePodcastsVisibility} />
                <Search homepage={true} />
                <PodcastChannelSelection ref={(podcasts) => { this.podcasts = podcasts; }} />
                <InFocusSection history={this.props.history} />
                <NewSection />
                <LatestResearch history={this.props.history} />
                <PopularSection history={this.props.history} />
                {/* <ExternalContent /> */}
                <SignUpSection />
                <FooterSection />
            </div>
        )
    }
}

export default HomePage;