import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './footer.style';
import * as homePageStyles from '../../home/homepage.style';
import logo from '../../../assets/icons/logo.svg'
import HeaderSection from '../header/header';
import HeaderTabletSection from '../header/header.tablet';
import NavBar from '../../navbar/navbar';
import * as navigation from '../../../configs/navigation';
import * as commonstyles from '../../common/common.style';

import Disclaimer from '../../disclaimer'

const AgreementSignedKey = 'AgreementSigned';
const DescribeProfessionalInvestorKey = 'DescribeProfessionalInvestor';

export default class FooterSection extends Component {
    constructor(props) {
        super(props);

        let agreementSigned = localStorage.getItem(AgreementSignedKey) || false;
        let describingProfesionalInvestor = localStorage.getItem(DescribeProfessionalInvestorKey) || false;
        this.state = { disclaimerModalIsOpen: !agreementSigned && !describingProfesionalInvestor };
        this.handleDisclaimerClose = this.handleDisclaimerClose.bind(this);
        this.handleDisclaimerOpen = this.handleDisclaimerOpen.bind(this);
        this.handleDisclaimerSigned = this.handleDisclaimerSigned.bind(this);
    }

    handleDisclaimerClose() {
        this.setState({ disclaimerModalIsOpen: false });
    }

    handleDisclaimerOpen() {
        this.setState({ disclaimerModalIsOpen: true });
    }

    handleDisclaimerSigned() {
        this.setState({ disclaimerModalIsOpen: false });
        localStorage.setItem(AgreementSignedKey, true);
    }

    render() {
        return (
            <div {...css(homePageStyles.mainContainer)}>

                <Disclaimer modalIsOpen={this.state.disclaimerModalIsOpen} onDisclaimerRequestingClose={this.handleDisclaimerClose}
                    onDisclaimerAgreementSigned={this.handleDisclaimerSigned} commr={this.props.commr} />

                <footer {...css(styles.footerContainer)}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 col-lg-9 col-sm-8 col-12">
                                <p {...css(styles.footerParagraph)}>
                                    {/* Danske Bank A/S, Holmens Kanal 2-12, 1092 København K. Tlf. +45 33 44 00 00, e-mail: danskebank@danskebank.dk, CVR-nr. 61 12 62 28, SWIFT: DABADKKK */}

                                    Danske Bank A/S, Bernstorffsgade 40, 1577 Copenhagen V. Tel. <a aria-label="(+45) 70 123 456" alt="(+45) 70 123 456" href="tel:+4533440000" {...css(styles.anchorLink)}>(+45) 70 123 456</a>, e-mail: <a aria-label="danskebank@danskebank.dk" alt="danskebank@danskebank.dk" href="mailto:danskebank@danskebank.dk" {...css(styles.anchorLink)}>danskebank@danskebank.dk</a>, CVR No. 61126228, SWIFT: DABADKKK.
                                    <br />
                                    Phone conversations may be recorded and stored due to documentation and security purposes
                                    <br /><br />
                                    Danske Bank reserves all rights. Danske Bank A/S is authorised by the
                                    &nbsp;
                                    <a aria-label="Danish Financial Supervisory Authority." alt="Danish Financial Supervisory Authority." {...css(styles.anchorLink)} target="blank" href={`https://www.finanstilsynet.dk/en/`}>Danish Financial Supervisory Authority.</a>
                                    &nbsp;
                                    Read
                                    &nbsp;
                                    <a aria-label="privacy statement and cookies" alt="privacy statement and cookies" {...css(styles.anchorLink)} target="blank" href={`https://danskeci.com/ci/cookie-policy`}>privacy statement and cookies</a>
                                    {/*terms of use code*/}
                                </p>
                                <br />{/* <br /> */}
                                <p {...css(styles.footerParagraphSmall)}>
                                    The material on this site is not intended for distribution to, or use by, any person in any jurisdiction or country where such distribution or use would be contrary to local law or regulation. It is your responsibility to inform yourself of and to observe all applicable laws and regulations of the relevant jurisdictions. Specifically, this website should not be accessed by any U.S. Person. Please see a definition of a U.S. Person at the end of this document.
                                </p>
                                <br />
                                <p {...css(styles.footerParagraphSmall)}>
                                    US Persons
                                    <br />
                                    A U.S. Person is any natural person resident in the United States (U.S.); or a company or partnership incorporated or organised in the US, but excluding an offshore branch or agency of a U.S. person that operates for valid business reasons and is engaged and regulated as an insurance company or bank; or a branch or agency of a foreign entity located in the U.S.; or a trust of which the trustee is a U.S. person, unless a non-U.S. person has or shares investment discretion; or an estate of which a US person is the executor or administrator, unless the estate is governed by foreign law and a non-US person has or shares investment discretion; or a non-discretionary account held for the benefit of a U.S. person; or a discretionary account held by a U.S. dealer or fiduciary, unless held for the benefit of a non-US person; or any entity organised or incorporated for the purposes of evading U.S. securities laws.
                                </p>
                            </div>
                            <br />
                            <div className="col-md-8 col-lg-9 col-sm-8 col-12" >
                                <p {...css(styles.footerParagraph)}>
                                    <a alt="Research Disclaimer" {...css(styles.anchorLink)} onClick={(event) => this.handleDisclaimerOpen()} > Research Disclaimer </a>
                                    &nbsp;
                                    &nbsp;
                                    <a aria-label="Further disclosures and disclaimers" alt="Further disclosures and disclaimers" {...css(styles.anchorLink)} target="blank" href={`https://danskeci.com/ci/research/research-disclaimer`}>Further disclosures and disclaimers</a>
                                </p>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-4 col-12">
                                <img className="float-right" {...css(styles.logo)} src={logo} alt={"Danske Bank"} />
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}
