import React from 'react';
import { css } from 'glamor';
import * as styles from './articlelistpage.style';
import HeaderSection from '../common/header/header';
import HeaderTabletSection from '../common/header/header.tablet';
import FooterSection from '../common/footer/footer';
import ListImageText from '../../components/listimagetext/listimagetext';
import NavBar from '../../components/navbar/navbar';
import * as queryHelper from '../../configs/query'
import httpProvider from '../../services/httpProvider.service';
import * as navigation from '../../configs/navigation';
import InfiniteScroll from 'react-infinite-scroll-component';
import Config from '../../app.config';
import * as languages from '../../configs/languages';
import * as translate from '../../assets/translations/translations';
import catalogTranslations from '../../assets/translations/subcategories.json';
import Search from '../search/search';
import spinner from '../../assets/icons/spinner.svg';

class ArticleListPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            articles: [],
            showToday: false,
            showArchive: false,
            title: '',
            hasMoreArticles: true,
            props: props,
            newprops: null,
            currentPath: null,
            newPath: null,
            articlesLoaded: false,
            showSearch: false,
            pageCount: 0,
            currentArticleListCount: 0,
            HamnavigationOpened: false
        };
        this.loadMoreArticles = this.loadMoreArticles.bind(this);
        this.showSearchComponent = this.showSearchComponent.bind(this);
        this.handleResponseBody = this.handleResponseBody.bind(this);
    }

    showSearchComponent = (value) => {
        this.setState({ showSearch: value });
        setTimeout(function () { if (document.getElementById("searchText") != null) { document.getElementById("searchText").focus(); } }, 300);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.makeRequest(this.props);
        document.title = this.state.title + ' - ' + translate.strings.TR14
    }

    componentWillReceiveProps(newProps) {
        window.scrollTo(0, 0);
        this.setState({ showSearch: false });
        this.setState({ HamnavigationOpened: false });
        this.setState({ currentArticleListCount: 0 });

        this.setState({ articles: [] })
        this.setState({ showToday: false })
        this.setState({ showArchive: false })
        this.setState({ articlesLoaded: false })
        this.setState({ pageCount: 0 });
        this.state.pageCount = 0;

        this.makeRequest(newProps);
        document.title = this.state.title + ' - ' + translate.strings.TR14
    }

    getLanguage() {
        return localStorage.getItem('language') || 'EN';
    }

    getLanguageLocale() {
        let language = this.getLanguage()
        let maybeLocale = languages.Languages.find((edition) => edition.edition == language)
        return maybeLocale ? maybeLocale.langaugeLocale : languages.DefaultLanguage.edition;
    }

    composeUrl(newProps, language) {
        let requestURL;
        let agreementAccepted = localStorage.getItem('agreementAccepted') == 'true' || false;
        let userAccess = agreementAccepted == true ? 'unrestricted' : 'restricted';
        let searchByLatest = newProps.match.params.searchByLatest ? true : false;

        if (newProps.match.path == '/articlelist/:search/:commr?') {
            var search = newProps.match.params.search.replace(/-/g, ' ');
            var encodedsearch = encodeURIComponent(search);
            requestURL = this.buildApiUrl(this.state.pageCount * 10, 10, userAccess, 'subcategory', encodedsearch, searchByLatest)
            if (language == 'EN' || language == 'ALL') {
                this.state.title = search;
            }
            else {
                this.state.title = this.getCatalogtranslations(search, language);
            }
        }
        else if (newProps.match.path == "/latestarticle") {
            requestURL = this.buildLatestUrl(this.state.pageCount * 10, 10, userAccess)
            this.state.title = translate.strings.TR220;
        }
        else if (newProps.match.path == "/keywords/:search") {
            requestURL = this.buildApiUrl(this.state.pageCount * 10, 10, userAccess, 'keywords', newProps.match.params.search, searchByLatest)
            this.state.title = newProps.match.params.search;
        }
        else if (newProps.match.path == "/category/:search") {
            var search = newProps.match.params.search.replace(/-/g, ' ');
            var encodedsearch = encodeURIComponent(search);
            requestURL = this.buildApiUrl(this.state.pageCount * 10, 10, userAccess, 'category', encodedsearch, searchByLatest)
            if (language == 'EN' || language == 'ALL') {
                this.state.title = search;
            }
            else {
                this.state.title = this.getCatalogtranslations(search, language);
            }
        }
        else {
            requestURL = this.buildApiUrl(this.state.pageCount * 10, 10, userAccess, 'wildcard', newProps.match.params.search, searchByLatest)
            this.state.title = newProps.match.params.search;
        }
        return requestURL;
    }


    buildApiUrl(offset, limit, access, option, search, searchByLatest = false) {
        return '?' + 'offset=' + offset + '&limit=' + limit + '&access=' + access + '&option=' + option + '&search=' + search + '&searchByLatest=' + searchByLatest;
    }

    buildLatestUrl(offset, limit, access) {
        return window.env.LatestApiUrl + 'offset=' + offset + '&limit=' + limit + '&access=' + access;
    }

    getCatalogtranslations(text, edition) {
        if (catalogTranslations[text]) {
            let translationElement = catalogTranslations[text];
            let translatedText = translationElement[edition];
            return translatedText;
        }
        else {
            return text;
        }
    }

    renderTodaysArticles(articles) {
        if (this.props.match.params.commr) return;

        return articles.map((article, key) => {
            let articleDate = new Date(article.published_date);

            if (this.isToday(articleDate)) {
                this.state.showToday = true;
                return this.renderArticleItem(article, key);
            }
        });
    }

    renderArticles(articles) {
        return articles.map((article, key) => {
            let articleDate = new Date(article.published_date);

            if (!this.isToday(articleDate) || this.props.match.params.commr) {
                this.state.showArchive = true;
                return this.renderArticleItem(article, key);
            }
        });
    }

    isToday(articleDate) {
        return articleDate && new Date().toDateString() === articleDate.toDateString();
    }

    renderArticleItem(article, key) {
        return (
            <div key={key} className="col-md-12 col-lg-12 col-sm-12 col-12">
                <ListImageText
                    keywords={article.keywords}
                    title={article.title}
                    categoryInfo={article.categoryInfo}
                    summary={article.summary}
                    filepath={article.published_url}
                    publisheddate={article.published_date}
                    thumbnail={article.thumbnailMobile}
                    thumbnail1={article.thumbnailMobile1}
                    thumbnail2={article.thumbnailMobile2}
                    thumbnail3={article.thumbnailMobile3}
                    thumbnail4={article.thumbnailMobile4}
                    mobiletext={article.mobile_text}
                    mobiletext1={article.mobile_text1}
                    mobiletext2={article.mobile_text2}
                    mobiletext3={article.mobile_text3}
                    mobiletext4={article.mobile_text4}
                    articleid={article.articleid}
                    langauge={article.language}
                    commr={this.props.match.params.commr}
                />
            </div>
        )
    }

    loadMoreArticles() {
        if (this.state.currentArticleListCount <= 0 || this.state.currentArticleListCount < (10 * this.state.pageCount)) return;
        this.makeRequest(this.props);
    }

    makeRequest(props) {
        if (this.props.match.path == "/latestarticle") {
            httpProvider.post(this.composeUrl(props, this.getLanguage()), { categoryInfo: [] })
                .then(this.handleResponseBody);
        }
        else {
            httpProvider.get(this.composeUrl(props, this.getLanguage()), { data: null })
                .then(this.handleResponseBody);
        }
    }

    handleResponseBody(res) {
        this.setState({ articlesLoaded: true })
        let articlesList = res.data.articles;
        if (articlesList && articlesList.length > 0) {
            this.state.pageCount = this.state.pageCount + 1;
            this.state.currentArticleListCount = this.state.currentArticleListCount + articlesList.length;

            this.setState({ articles: this.state.articles.concat(articlesList) });
        }

        this.setState({ hasMoreArticles: (this.state.currentArticleListCount > 0 && this.state.currentArticleListCount == ((this.state.pageCount) * 10)) })
    }

    companyDescription() {
        const company = translate.companyDescriptions.find(c => c.company == this.state.title)
        return company === undefined ? null : company.description
    }

    render() {
        return (
            <div>
                <HeaderTabletSection history={this.props.history} hideNav={this.state.HamnavigationOpened} />
                <HeaderSection showSearch={this.showSearchComponent} showSearchButton={!this.props.match.params.commr} history={this.props.history} />
                {this.props.match.params.commr ? <NavBar items={navigation.commrNavInfo} commr={true} /> : <NavBar items={navigation.navInfo} />}
                {(this.state.showSearch || this.props.match.path == '/articlesearch/:search/:searchByLatest?')
                    ? <Search homepage={false} searchkey={this.props.match.params.search} searchByLatest={this.props.match.params.searchByLatest ? true : false} />
                    : null}
                <div className="container-fluid  article-list" {...css(styles.articleListContent)}>
                    <div className="row">
                        <div className="col-md-3 col-lg-3 col-sm-12 col-12">

                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                            {(this.props.match.path == '/articlesearch/:search/:searchByLatest?')
                                ?
                                <section>
                                    {(this.state.articles.length <= 0 && this.state.articlesLoaded) ? <div><p {...css(styles.paragraph)}>{translate.strings.TR78}</p>
                                        <br /><p {...css(styles.paragraph)}>{translate.strings.TR79}
                                            <br /><br />
                                            {translate.strings.TR80}
                                            <br />
                                            {translate.strings.TR81}</p></div> : null}
                                </section>

                                :
                                <div>
                                    {this.state.showSearch
                                        ?
                                        <section>
                                            {(this.state.articles.length <= 0 && this.state.articlesLoaded) ? <div><p {...css(styles.paragraph)}>{translate.strings.TR78}</p>
                                                <br /><p {...css(styles.paragraph)}>{translate.strings.TR79}
                                                    <br /><br />
                                                    {translate.strings.TR80}
                                                    <br />
                                                    {translate.strings.TR81}</p></div> : null}
                                        </section>
                                        :
                                        <section {...css(styles.articleListHeading)} role="region" aria-label={this.state.title}>
                                            <h1 {...css(styles.H1)}>{this.state.title}</h1>
                                            {this.props.match.params.commr ?
                                                <div>
                                                    <p {...css(styles.paragraph)}>
                                                        {
                                                            this.state.title
                                                                ? this.companyDescription()
                                                                : null
                                                        }
                                                    </p>
                                                </div>
                                                : null
                                            }
                                            {(this.state.articles.length <= 0 && this.state.articlesLoaded)
                                                ?
                                                <div>
                                                    <p {...css(styles.paragraph)}>{translate.strings.TR78}</p> <br />
                                                    <p {...css(styles.paragraph)}>
                                                        {translate.strings.TR79} <br /><br />
                                                        {translate.strings.TR80} <br />
                                                        {translate.strings.TR81}
                                                    </p>
                                                </div>
                                                : null}
                                        </section>}
                                </div>}
                        </div>
                    </div>

                    {this.state.showToday ? <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-12" role='region' aria-label={translate.strings.TR221}>
                            <p {...css(styles.H5)}>{translate.strings.TR221}</p>
                        </div>
                    </div> : null}

                    <div className="row today-articles">
                        {this.renderTodaysArticles(this.state.articles)}
                    </div>

                    {this.state.showArchive ? <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-12" role="region" aria-label={translate.strings.TR196}>
                            <p {...css(styles.H5)} >{this.props.match.params.commr ? "Research" : translate.strings.TR196}</p>
                        </div>
                    </div> : null}
                    <div className="row infinite-scroll">
                        <InfiniteScroll {...css(styles.infiniteScroll)}
                            dataLength={this.state.articles.length}
                            next={this.loadMoreArticles}
                            hasMore={this.state.hasMoreArticles}
                            loader={<img src={spinner} {...css(styles.spinner)} alt={translate.strings.TR262} aria-label={translate.strings.TR262} />}>
                            {this.renderArticles(this.state.articles)}
                        </InfiniteScroll>
                    </div>


                </div>

                <FooterSection commr={this.props.match.params.commr} />
            </div >
        )
    }
}

export default ArticleListPage;
