import React from 'react';
import HeaderSection from '../../common/header/header';
import FooterSection from '../../common/footer/footer';
import NavBar from '../../../components/navbar/navbar';
import * as navigation from '../../../configs/navigation';
import * as styles from '../../article/articlepage.style';
import { css } from 'glamor';
import Section from '../../../components/section/section';
import * as translate from '../../../assets/translations/translations';

class CommrAboutPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = 'About Commissioned Research - ' + translate.strings.TR249
    }

    render() {
        return (
            <div>
                <HeaderSection showSearch={this.showSearchComponent} history={this.props.history} commr={true} />
                <NavBar items={navigation.commrNavInfo} commr={true} />
                <div className="container-fluid" {...css(styles.articleContent)} role="region" aria-label='About Commissioned Research'>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-12" {...css(styles.padding0)}>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-12" {...css(styles.padding0)}>
                            {
                                <h2 {...css(styles.H2WithoutUnderline)}>About Commissioned Research</h2>
                            }
                        </div>
                    </div>
                    <Section image={null} summary={
                        `<p>Our Commissioned Research offers insights and information on selected listed companies in the Nordic region. It is produced by Danske Bank’s highly rated Equity Research team.</p>
                        <br>
                        <p>The research has been commissioned by the companies, but the reports are prepared in accordance with the recommendations of the Danish Securities Dealers Association and are based on our general principles of research objectivity and independence. The research reports do not provide a recommendation or target price, but include a valuation discussion and suggested valuation range. While our Commissioned Research is available to investors in EEA member states, it cannot be distributed to the US.</p>`
                    } />
                </div>
                <FooterSection commr={true} />
            </div>
        )
    }
}

export default CommrAboutPage;