export const H2 = {
    textAlign: 'center',
    color: '#003755',
    font: '500 2.75em Danske Human',
    margin: '105px 0 25px 0',
    '@media(min-width:0px) and (max-width:767px)': {
        margin: '50px 0 5px 0',
        font: '500 2em Danske Human'
    }

};
export const categoryH2 = {
    textAlign: 'center',
    color: '#003755',
    font: '500 2.75em Danske Human',
    margin: '105px 0 10px 0',
    '@media(min-width:0px) and (max-width:767px)': {
        margin: '50px 0 5px 0',
        font: '500 2em Danske Human'
    }

};
export const subscriptionContent = {
    minHeight: 'calc(100vh - 450px) !important',
    margin: '0 auto'
}
export const categoryButtons = {
    height: '80px',
    border: '1px solid rgba(0,55,85,0.12)',
    background: 'linear-gradient(0deg, #F5F5F5 0%, #FFFFFF 47.23%, #FFFFFF 100%)',
    color: '#003755',
    fontSize: '1em',
    fontWeight: '600',
    padding: '30px 25px 15px',
    display: 'block',
    width: '100%',
    margin: '0 auto 25px',
    cursor: 'pointer'
}
export const paragraph = {
    color: '#003755',
    fontSize: '1em',
    marginBottom: '10px'
}
export const Button = {
    border: '1px solid #37B0DE',
    borderRadius: '40px',
    //background: 'linear-gradient(0deg, #0097CF 0%, #00AAE8 100%)',
    padding: '15px 50px',
    textAlign: 'center',
    //color: '#FFF',
    textDecoration: 'none',
    display: 'table',
    margin: '50px auto 0',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'none',
    }
};

export const anchor = {
    color: '#008CBF',
    '&:hover': {
        color: '#008CBF'
    }
}

export const subTitle = {
    color: '#003755',
    fontSize: '1em',
    textAlign: 'center'
}
export const categorySubTitle = {
    color: '#003755',
    fontSize: '1em',
    textAlign: 'center',
    marginBottom: '50px'
}
export const collapsibleContainer = {
    marginTop: '50px',
    cursor: 'pointer'
}
export const subCategory = {
    color: '#003755',
    fontSize: '1em'
}
export const subCategoryCountry = {
    // color: '#7F9BAA',
    color: '#556D7E',
    fontSize: '.875em'
}
export const headingRow = {
    padding: '10px 0 5px 0',
    borderBottom: '1px solid rgba(0,55,85,0.2)'
}
export const categoryDetails = {
    color: '#003755',
    fontSize: '1em',
    padding: '15px 0',
    marginBottom: '0'
}
export const noPadding = {
    padding: '0 !important'
}
export const inputLabel = {
    display: 'inline-block',
    paddingBottom: '10px',
    fontSize: '.875em',
    color: '#003755'
};
export const inputText = {
    width: '100%',
    padding: '0 20px',
    fontSize: '16px',
    color: '#003755',
    borderRadius: '2px',
    border: '1px solid #dbe0de',
    height: '48px'
};
export const fieldsContainer = {
    margin: '25px auto 0',
    width: '60%'
};

export const consentBox = {
    margin: '25px auto 0',
    width: '60%'
};

export const paragraphForm = {
    color: '#003755',
    fontSize: '1em',
    marginBottom: '10px',
    width: '60%',
    margin: '25px auto 0'
};
export const paragraphError = {
    color: '#003755',
    fontSize: '1em',
    marginBottom: '10px',
    width: '60%',
    margin: '25px auto 0',
    color: '#fbb273'
};
export const paragraphSubtitle = {
    color: '#003755',
    fontSize: '1em',
    marginBottom: '10px',
    width: '60%',
    margin: '25px auto 0',
    textAlign: 'center'
};
export const greenTick = {
    float: 'right',
    marginRight: '10%'
}
export const categoryContainer = {
    width: '80%',
    margin: '0 auto'
}
export const contactMessage = {
    display: 'block',
    fontWeight: 'bold'
}
export const checkboxContainer = {
    display: 'inline-block',
    width: '10%',
    verticalAlign: 'top',
    textAlign: 'center'
}
export const agreementText = {
    display: 'inline-block',
    width: '90%'
}
export const pading0 = {
    paddingLeft: '0 !important',
    paddingRight: '0 !important'
}

export const alertDangerCss = {
    background: '#FFEFF0 !important',
    color: '#B32D45 !important'
}

export const alertDangerInputBox = {
    border: '1px solid red !important'
}