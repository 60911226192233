import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'glamor';
import axios from 'axios';
import * as styles from '../articlelist/articlelistpage.style';
import * as commonstyles from '../common/common.style';
import * as aboutPageStyles from './aboutPages.style';
import * as translate from '../../assets/translations/translations';
import Config from '../../app.config';
import HeaderSection from '../../components/common/header/header';
import HeaderTabletSection from '../../components/common/header/header.tablet';
import FooterSection from '../../components/common/footer/footer';
import NavBar from '../../components/navbar/navbar';
import * as navigation from '../../configs/navigation';
import * as languages from '../../configs/languages';
import Search from '../search/search';

export default class AboutDanskeBankResearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false
        };
        this.showSearchComponent = this.showSearchComponent.bind(this);
    }
    componentDidMount() {
        document.title = translate.strings.TR198 + ' - ' + translate.strings.TR14
    }

    showSearchComponent = (value) => {
        this.setState({ showSearch: value });
        setTimeout(function () { if (document.getElementById("searchText") != null) { document.getElementById("searchText").focus(); } }, 300);
    }


    render() {
        return (
            <div>
                <HeaderTabletSection history={this.props.history} />
                <HeaderSection showSearch={this.showSearchComponent} history={this.props.history} />
                <NavBar items={navigation.navInfo} />
                {this.state.showSearch ? <Search homepage={false} /> : null}
                <div className="row">
                    <div className="col-md-3 col-lg-3 col-sm-12 col-12">
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                        <section {...css(aboutPageStyles.heading)} role="region" aria-label={translate.strings.TR198}>
                            {/* <h2 {...css(commonstyles.H1Left)}>{translate.strings.TR198}</h2> */}
                            <h1 {...css(commonstyles.H1Left)}>{translate.strings.TR198}</h1>

                            <div className="col-md-12 col-lg-12 col-sm-12 col-12"  {...css(commonstyles.nopadding)}>
                                <br />
                                <p {...css(aboutPageStyles.aboutBankResearchParagraph)}>{translate.strings.TR199}</p>
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12 col-12"  {...css(commonstyles.nopadding)}>
                                <br />
                                {/* <h4 {...css(commonstyles.H4Left)}>{translate.strings.TR200}</h4> */}
                                <h2 {...css(commonstyles.H4Left)}>{translate.strings.TR200}</h2>
                                <p {...css(aboutPageStyles.aboutBankResearchParagraph)}>{translate.strings.TR201}</p>
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12 col-12"  {...css(commonstyles.nopadding)}>
                                <br />
                                <h2 {...css(commonstyles.H4Left)}>{translate.strings.TR202}</h2>
                                <p {...css(aboutPageStyles.aboutBankResearchParagraph)}>{translate.strings.TR203}</p>
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12 col-12"  {...css(commonstyles.nopadding)}>
                                <br />
                                <h2 {...css(commonstyles.H4Left)}>{translate.strings.TR204}</h2>
                                <p {...css(aboutPageStyles.aboutBankResearchParagraph)}>{translate.strings.TR205}</p>
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12 col-12"  {...css(commonstyles.nopadding)}>
                                <br />
                                <h2 {...css(commonstyles.H4Left)}>{translate.strings.TR208}</h2>
                                <p {...css(aboutPageStyles.aboutBankResearchParagraph)}>{translate.strings.TR209}</p>
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12 col-12"  {...css(commonstyles.nopadding)}>
                                <br />
                                <h2 {...css(commonstyles.H4Left)}>{translate.strings.TR210}</h2>
                                <p {...css(aboutPageStyles.aboutBankResearchParagraph)}>{translate.strings.TR211}<br />
                                    <em {...css(commonstyles.standardColor, aboutPageStyles.equityFont)}> {translate.strings.TR212} </em>{translate.strings.TR213}<br />
                                    <em {...css(commonstyles.standardColor, aboutPageStyles.equityFont)}> {translate.strings.TR214}</em>{translate.strings.TR215}
                                </p>
                            </div>
                        </section>

                        <section {...css(aboutPageStyles.heading, commonstyles.nopadding)} role="region" aria-label={translate.strings.TR216}>
                            <h2 {...css(commonstyles.H1Left)}>{translate.strings.TR216}</h2>
                            <div className="col-md-12 col-lg-12 col-sm-12 col-12"  {...css(commonstyles.nopadding)}>
                                <br />
                                <p {...css(aboutPageStyles.aboutBankResearchParagraph)}>{translate.strings.TR217}</p>
                            </div>
                            <br /><br />
                        </section>

                    </div>
                </div>
                <FooterSection />
            </div>
        )
    }
}

